import { Ability, AbilityBuilder, AbilityClass } from "@casl/ability";
import { Injectable } from "@angular/core";
import { IUser } from "../../models/user/user.model";

type Actions = 'access';
type Subjects = 'PREMIUM_CONTENT';

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

@Injectable()
export class AppAbilityService {
  constructor(
    private ability: Ability,
  ) {
  }

  defineAbilitiesFor(user: IUser) {
    const {can, rules, cannot} = new AbilityBuilder(AppAbility);
    if (user.planTypeId) {
      can('access', 'PREMIUM_CONTENT');
    } else {
      cannot('access', 'PREMIUM_CONTENT');
    }

    return rules;
  }

  updateAbilities(user: IUser) {
    const rules = this.defineAbilitiesFor(user);
    this.ability.update(rules);
  }
}
