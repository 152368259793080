import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { IUser } from "../../models/user/user.model";

@Injectable()
export class UserService {
  constructor(
    private apiService: ApiService
  ) {
  }

  userProfileIsCompleted(user: IUser) {
    const notCompleted = (!user.username) || (!user.birthDate) || (!user?.userInfo?.cityId) ||
      (!user?.userInfo?.districtId) || (!user?.userInfo?.schoolId) || (!user?.userInfo?.gradeId) ||
      (!user?.userInfo?.departmentId);

    return !notCompleted;
  }
}
